import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import Confetti from "react-confetti";

interface QuizResult {
  question: string;
  studentAnswer: string;
  isCorrect: boolean;
  attempted: boolean;
  percentage: number;
}

interface LeaderboardEntry {
  name: string;
  score: number;
}

interface Topic {
  id: string;
  name: string;
}

interface QuizState {
  loading: boolean;
  error: string | null;
  quizResults: QuizResult[];
  leaderboardData: LeaderboardEntry[];

  topics: Topic[];
  selectedTopic: string;
  showQuizResults: boolean; // New state to toggle between podium and quiz results
}

const PodiumPlatform = ({ position, height, imageSrc }) => (
  <Box
    sx={{
      width: position === 1 ? 120 : 120,
      height,
      backgroundColor: "grey.300",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 1,
      marginX: 1, // Add some space between podiums
      position: "relative",
    }}
  >
    {/* Podium Number */}
    <Typography
      variant="h3"
      component="div"
      sx={{
        // color: position === 1 ? "orange" : position === 2 ? "silver" : "brown",
        color: position === 1 ? "black" : position === 2 ? "black" : "black",
        fontWeight: "bold",
        marginBottom: 1,
      }}
    >
      {position}
    </Typography>

    {/* Avatar Image */}
    <Box
      component="img"
      src={imageSrc}
      alt={`Position ${position}`}
      sx={{
        width: 80,
        height: 80,
        borderRadius: "50%",
        border: "2px solid grey",
        objectFit: "cover",
        marginTop: -25, // Adjust for spacing between number and avatar
      }}
    />
  </Box>
);

const Podium = () => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showConfetti && <Confetti />}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center", // Center podiums
          height: "auto", // Adjust to auto so that it doesn't push the table down
          position: "relative",
          paddingTop: 20, // Extra space for avatars
        }}
      >
        <PodiumPlatform
          position={2}
          height={100} // Reduced height for 2nd place
          imageSrc="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fishita-niwas-gn-ii.webp?alt=media&token=52a5e607-a44a-41ae-8c12-d40bc2c7bcb2" // Placeholder image for 2nd place
        />
        <PodiumPlatform
          position={1}
          height={120} // Reduced height for 1st place
          imageSrc="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fimages%20(6).jfif?alt=media&token=debbe6e2-26a4-41e1-9cc5-ccde771eb6a2" // Placeholder image for 1st place
        />
        <PodiumPlatform
          position={3}
          height={80} // Reduced height for 3rd place
          imageSrc="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fdownload%20(5).jfif?alt=media&token=6dc01496-1c8e-44fc-81a4-ec7688085a9b" // Placeholder image for 3rd place
        />
      </Box>
    </>
  );
};

const getBackgroundColor = (percentage) => {
  if (percentage === 100) {
    return "#e8f5e9";
  } // Light green
  if (percentage === 0) {
    return "#ffebee";
  } // Light red
  return "#ffffff"; // White for scores between 0 and 100
};

interface Topic {
  id: string;
  name: string;
}

class StudentQuizResult extends Component<{}, QuizState> {
  state: QuizState = {
    loading: true,
    error: null,

    quizResults: [],
    leaderboardData: [
      { name: "Alice", score: 95 },
      { name: "Bob", score: 90 },
      { name: "Charlie", score: 85 },
    ],

    topics: [
      { id: "chemistry", name: "Quiz-Chemistry" },
      { id: "physics", name: "Quiz-Physics" },
      { id: "biology", name: "Quiz-Biology" },
    ],
    selectedTopic: "",
    showQuizResults: false, // Initially show the podium
  };

  componentDidMount() {
    // Simulate data fetching
    setTimeout(() => {
      //this.setState({ loading: false });
      this.setState({ loading: false, selectedTopic: "chemistry" });
      this.fetchQuizResults("chemistry");
    }, 1000);
  }

  fetchQuizResults = (topicId: string) => {
    // Simulate fetching quiz results based on the selected topic
    const dummyResults: { [key: string]: QuizResult[] } = {
      chemistry: [
        {
          question: "1. What is the chemical formula of water?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "2. What is the atomic number of carbon?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "3. What is the pH value of pure water?",
          studentAnswer: "90/100",
          isCorrect: true,
          attempted: true,
          percentage: 90,
        },
        {
          question: "4. What is the main component of natural gas?",
          studentAnswer: "75/100",
          isCorrect: true,
          attempted: true,
          percentage: 75,
        },
        {
          question: "5. Who is known as the father of modern chemistry?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        {
          question: "6. What is the molecular formula of methane?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        // ... (add more biology questions)
      ],
      physics: [
        {
          question: "1. What is Newton's first law of motion?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "2. What is the speed of light in vacuum?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "3. What is the unit of electrical resistance?",
          studentAnswer: "75/100",
          isCorrect: true,
          attempted: true,
          percentage: 75,
        },
        {
          question:
            "4. What is the value of gravitational acceleration on Earth?",
          studentAnswer: "55/100",
          isCorrect: true,
          attempted: true,
          percentage: 55,
        },
        {
          question: "5. What is the formula for kinetic energy?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        {
          question: "6. Who discovered the law of universal gravitation?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        // ... (add more physics questions)
      ],
      biology: [
        {
          question: "1. What is the function of mitochondria in a cell?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "2. What is the process of photosynthesis?",
          studentAnswer: "10/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question:
            "3. What is the genetic material found in the nucleus of a cell?",
          studentAnswer: "73/100",
          isCorrect: true,
          attempted: true,
          percentage: 73,
        },
        {
          question: "4. What is the primary function of red blood cells?",
          studentAnswer: "60/100",
          isCorrect: true,
          attempted: true,
          percentage: 60,
        },
        {
          question: "5. What is the biological classification system?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        {
          question: "6. What is the role of DNA in inheritance?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        // ... (add more biology questions)
      ],
    };

    this.setState({ quizResults: dummyResults[topicId] || [] });
  };

  handleTopicChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const topicId = event.target.value as string;
    this.setState({ selectedTopic: topicId });
    this.fetchQuizResults(topicId);
  };

  renderPodium1 = () => (
    <Box display="flex" justifyContent="center" alignItems="flex-end" mb={4}>
      {this.state.leaderboardData.map((student, index) => (
        <Box key={student.name} mx={2} textAlign="center">
          <Paper
            elevation={3}
            style={{
              height: `${100 - index * 20}px`,
              width: "80px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              padding: "10px",
              backgroundColor: ["gold", "silver", "#cd7f32"][index],
            }}
          >
            <EmojiEventsIcon />
            <Typography variant="subtitle2">{student.name}</Typography>
            <Typography variant="body2">{student.score}%</Typography>
          </Paper>
        </Box>
      ))}
    </Box>
  );

  renderPodium = () => <Podium />;

  toggleQuizResults = () => {
    this.setState((prevState) => ({
      showQuizResults: !prevState.showQuizResults,
    }));
  };

  render() {
    // const { loading, error, quizResults } = this.state;
    const {
      loading,
      error,
      quizResults,
      topics,
      selectedTopic,
      showQuizResults,
    } = this.state;

    if (loading) {
      return (
        <Container
          maxWidth="md"
          style={{ textAlign: "center", marginTop: "50px" }}
        >
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading quiz results...
          </Typography>
        </Container>
      );
    }

    if (error) {
      return (
        <Container
          maxWidth="md"
          style={{ textAlign: "center", marginTop: "50px" }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Container>
      );
    }

    //const correctAnswers = quizResults.filter((result) => result.isCorrect);

    return (
      //   <Container maxWidth="md" style={{ marginTop: "30px" }}>
      <Box sx={{ height: "100vh", overflowY: "scroll", padding: 0, margin: 3 }}>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          {/* Champions */}
          <img
            src="../assets/reward.png"
            height={60}
            style={{
              marginTop: "0",
              marginBottom: "0",
              verticalAlign: "middle",
            }}
          />
        </Typography>

        {/* added form label for topic selection suha */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0",
            marginBottom: "0",
            verticalAlign: "middle",
          }}
        >
          <FormControl
            fullWidth
            sx={{
              maxWidth: 250,
              textAlign: "center",
              marginTop: "0",
              marginBottom: "0",
              verticalAlign: "middle",
            }}
          >
            {/* <InputLabel id="topic-select-label">Choose Topic</InputLabel> */}
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedTopic}
              label="Choose Topic"
              // onChange={this.handleTopicChange}
              onChange={
                (this.handleTopicChange = this.handleTopicChange.bind(this))
              }
              sx={{
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  display: "none",
                },
                // Increase font size for the selected item and dropdown menu
                "& .MuiSelect-select": {
                  fontSize: "1.2rem", // Adjust the font size as needed
                },
                "& .MuiMenuItem-root": {
                  fontSize: "1.2rem", // Adjust the font size for menu items
                },
              }}
            >
              {topics.map((topic) => (
                <MenuItem
                  key={topic.id}
                  value={topic.id}
                  style={{
                    marginTop: "0",
                    marginBottom: "0",
                    verticalAlign: "middle",
                  }}
                >
                  {topic.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {this.renderPodium()}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                width: "100%",
                overflow: "hidden",
                mb: 4,
                // overflowY: "scroll",
                // height: "500px",
              }}
            >
              <Table aria-label="quiz results table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Questions
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {/* Attempted */}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Percentage
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Attempts
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {quizResults.map((result, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: getBackgroundColor(result.percentage),
                      }}
                    >
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", color: "#3f51b5" }}
                        >
                          {result.question}
                        </Typography>
                      </TableCell>

                      {/* UN USED FIELD attempted*/}
                      <TableCell>
                        <Typography variant="body2">
                          {result.attempted ? "" : ""}
                        </Typography>
                      </TableCell>
                      {/* UN USED FIELD attempted*/}

                      <TableCell>
                        <Typography variant="body2">
                          {result.percentage}%
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          color={result.isCorrect ? "green" : "red"}
                        >
                          {result.isCorrect ? (
                            <CheckCircleIcon sx={{ mr: 1 }} />
                          ) : (
                            <CancelIcon sx={{ mr: 1 }} />
                          )}
                          <Typography
                            variant="body2"
                            sx={{ color: result.isCorrect ? "green" : "red" }}
                          >
                            {result.studentAnswer}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        {/* </Container> */}
      </Box>
    );
  }
}

export default StudentQuizResult;

/* eslint-disable curly */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  Alert,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import { Link, useNavigate } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Chip,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import axios from "axios";

const API_URL = "http://192.168.29.224/platform/update-icc-status/";

const ProgressBar = ({
  duration,
  index,
  onComplete,
  blockId,
  status,
  trackId,
  planDay,
  isActive,
  updateICCStatus,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return oldProgress + 100 / (duration * 60);
        });
      }, 1000);
    } else {
      setProgress(0);
    }
    return () => clearInterval(interval);
  }, [isActive, duration]);

  useEffect(() => {
    if (progress >= 100) {
      updateICCStatus(blockId, status).then((response) => {
        console.log("API Response Data:", response);
        onComplete(index);
      });
    }
  }, [progress, blockId, status, onComplete, index, updateICCStatus]);

  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{
        height: "6px",
        borderRadius: "4px",
        backgroundColor: "#c1c1c1",
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#90ee90",
        },
      }}
    />
  );
};

export const DrawerContent = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeBlockIndex, setActiveBlockIndex] = useState(0);

  // const { plan } = props;
  const { plan, trackId, planDay } = props;

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    setActiveBlockIndex(0);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setActiveBlockIndex(0);
  };

  console.log("DrawerContent plan", plan);

  const parseJSON = (jsonString: string) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  };

  const handleBlockComplete = useCallback(
    (index) => {
      console.log(`Block ${index} completed`);
      if (index < plan.data.length - 1) {
        setActiveBlockIndex((prevIndex) => prevIndex + 1);
      }
    },
    [plan],
  );


  const updateICCStatus = useCallback(
    async (blockId, status) => {
      const body = {
        icc_tracking_id: trackId,
        staff_id: "873",
        lpbpab_id: blockId,
        status: status,
        icc_day: planDay,
        classroom_id: "4216",
      };

      try {
        const response = await axios.put(API_URL, body);
        console.log("API Response check:", response.data);
        console.log("Full API Response:", response);
        return response.data;
      } catch (error) {
        console.error("Error updating ICC status:", error);
        throw error;
      }
    },
    [trackId, planDay],
  );

  const INSTRUCTIONS_PANEL = () => {
    const { names_dict, data: instructionBlocks } = plan;

    return (
      // sx={{ p: 3 }} removed padding
      <Box>
        {/* Lesson Plan Title */}
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
          Lesson Plan: {names_dict.chapter_name}
        </Typography>

        {/* Day Text */}
        <Typography
          variant="subtitle1"
          color="text.secondary"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Day {names_dict.icc_day}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Timeline
          sx={{
            [`& .MuiTimelineItem-root:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {instructionBlocks.map((block, index) => {
            const content = parseJSON(block.generated_text);
            if (!content) return null;

            return (
              <TimelineItem key={block.icc_id}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  {/* <TimelineDot color={index <= activeBlockIndex ? "primary" : "grey"} /> */}
                  {index < instructionBlocks.length - 1 && (
                    <TimelineConnector />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Paper
                    elevation={3}
                    sx={{ p: 2, mb: 2, ":hover": { boxShadow: 6 } }}
                  >
                    {/* Progress Bar for each lesson block */}

                    <ProgressBar
                      duration={content.time}
                      index={index}
                      onComplete={handleBlockComplete}
                      blockId={block.icc_id}
                      status={content.status}
                      trackId={trackId}
                      planDay={planDay}
                      isActive={drawerOpen && index === activeBlockIndex}
                      updateICCStatus={updateICCStatus}
                    />

                    {/* adding styling and change placing */}
                    <Typography
                      variant="h6"
                      component="h3"
                      gutterBottom
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 5,
                        marginTop: 5,
                      }}
                    >
                      {block.lpbpab_name}

                      <Chip
                        label={`${content.time} min`}
                        size="small"
                        //color="primary"
                        sx={{
                          ml: 2,
                          color: "primary.main",
                          border: "0.5px solid", // Outlined border
                          borderColor: "primary.main", // Border color using primary
                        }}
                        // color={index <= activeBlockIndex ? "primary" : "default"}
                      />
                    </Typography>

                    <Typography variant="body2" paragraph>
                      {content.text}
                    </Typography>

                    {/*  removed day 1text in single card and change the time placing
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          label={`${content.time}`}
                          size="small"
                          color="primary"
                        />
                    
                        <Typography variant="caption" color="text.secondary">
                          {content.day}
                        </Typography> 

                      </Box> 
                    */}

                    {/* removed topic name in single cards */}
                    {/* 
                        {content.topics && (
                        <List dense>
                          {content.topics.map((topic) => (
                            <ListItem key={topic["topic-id"]}>
                              <ListItemText primary={topic.topic_name} />
                            </ListItem>
                          ))}
                        </List>
                        )} 
                    */}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Box>
    );
  };

  // UNUSED
  const INSTRUCTIONS_PANEL_OLD = () => {
    return (
      <Box sx={{ width: 250, padding: 2 }} role="presentation">
        {/* Close button inside drawer */}
        <IconButton onClick={handleDrawerClose} style={{ float: "right" }}>
          <CloseIcon />
        </IconButton>

        {/* Instructional Content for Students */}
        <Typography
          variant="h5"
          sx={{ color: "#1976d2", marginBottom: 2, fontWeight: "bold" }}
        >
          Instructions for Students
        </Typography>

        <ul
          style={{
            lineHeight: "1.8em",
            fontSize: "1rem",
            paddingLeft: "1.2em",
            color: "#555", // Gray color for text
            fontFamily: "Arial, sans-serif",
          }}
        >
          <li>Follow the guidelines carefully before starting the test.</li>
          <li>Ensure that you have a stable internet connection.</li>
          <li>Complete the test within the given time frame.</li>
          <li>Do not close the browser window during the test.</li>
          <li>Contact your instructor if you face any issues.</li>
        </ul>

        <Typography
          sx={{
            marginTop: "1.5em",
            fontSize: "1rem",
            color: "#777", // Slightly lighter gray for closing message
            fontFamily: "Arial, sans-serif",
            alignItems: "center",
          }}
        >
          Good luck!
        </Typography>
      </Box>
    );
  };

  return (
    <div>
      {/* Link to trigger the drawer */}
      <Link
        onClick={handleDrawerOpen}
        style={{
          fontSize: "1.2em",
          cursor: "pointer",
          justifyContent: "center",
          display: "block",
        }}
        to={""}
      >
        <DescriptionOutlinedIcon
          style={{ color: "rgb(10,10,10)", fontSize: "17px" }}
        />
      </Link>

      {/* Drawer Component */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 600, padding: 2 }} role="presentation">
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {/* Drawer Content */}
          {plan ? (
            <>
              <INSTRUCTIONS_PANEL />
            </>
          ) : (
            <>
              {/* no plan */}
              <Alert variant="filled" severity="error">
                No lesson plan available. Please create a lesson plan first.
              </Alert>
            </>
          )}
        </Box>
      </Drawer>
    </div>
  );
};

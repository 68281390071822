import React, { Component, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import Confetti from "react-confetti";
import "./Quiz.css";
// import Timer from "./frags/Timer";
import FullScreenTimer from "./frags/FullScreenTimer";
import Dice from "./frags/Dice";

interface QuizResult {
  question: string;
  studentAnswer: string;
  isCorrect: boolean;
  attempted: boolean;
  percentage: number;
}

interface LeaderboardEntry {
  name: string;
  score: number;
}

interface Topic {
  id: string;
  name: string;
}

interface QuizState {
  loading: boolean;
  error: string | null;
  quizResults: QuizResult[];
  leaderboardData: LeaderboardEntry[];

  topics: Topic[];
  selectedTopic: string;
  showQuizResults: boolean; // New state to toggle between podium and quiz results

  timerDuration: number;
}

interface FullScreenTimerProps {
  initialDuration: number;
  onTimerEnd: () => void;
}

{
  /* podium styles suha*/
}
const PodiumPlatform = ({ position, height, imageSrc }) => (
  <Box
    sx={{
      width: position === 1 ? 220 : 220,
      height: position === 1 ? 400 : 300,
      //   height: height,
      backgroundColor: "grey.300",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: -10,
      marginX: 1, // Add some space between podiums
      position: "relative",
    }}
  >
    {/* Podium Number */}
    <Typography
      variant="h3"
      component="div"
      sx={{
        // color: position === 1 ? "orange" : position === 2 ? "silver" : "brown",
        color: position === 1 ? "black" : position === 2 ? "black" : "black",
        fontWeight: "bold",
        marginBottom: 1,
      }}
    >
      {position}
    </Typography>

    {/* Placeholder text based on position */}
    <Typography
      variant="body1"
      component="div"
      sx={{
        color: "gray", // You can adjust the color if needed
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      {/* 13 questions answered correct */}
      {position === 1 && "13 Questions Answered Correct"}
      {position === 2 && "10 Questions Answered Correct"}
      {position === 3 && "09 Questions Answered Correct"}
    </Typography>

    {/* Avatar Image */}
    <Box
      component="img"
      src={imageSrc}
      alt={`Position ${position}`}
      sx={{
        width: 100,
        height: 100,
        borderRadius: "50%",
        border: "2px solid grey",
        objectFit: "cover",
        marginTop: -32, // Adjust for spacing between number and avatar
      }}
    />

    {/* Student Name below the image */}
    <Typography
      variant="h6"
      component="div"
      sx={{
        color: "black", // Adjust the color as needed
        fontWeight: "bold",
        textAlign: "center",
        marginTop: 1, // Adjust for spacing between image and name
      }}
    >
      {/* Use the actual student name variable here */}
      {position === 1 && "Abu"}
      {position === 2 && "Suhasini"}
      {position === 3 && "Jyo"}
    </Typography>
  </Box>
);

const Podium = (props) => {
  const [showConfetti, setShowConfetti] = useState(true);
  const [zoomed, setZoomed] = useState(true);
  const { showQuizResults } = props;

  const handleZoom = () => {
    setZoomed(!zoomed);
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showConfetti && <Confetti />}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center", // Center podiums
          height: "auto", // Adjust to auto so that it doesn't push the table down
          position: "relative",
          paddingTop: 20, // Extra space for avatars
          transition: "transform 0.3s ease-in-out",
          transform: !showQuizResults ? "scale(1)" : "scale(0.5)",
        }}
      >
        <PodiumPlatform
          position={2}
          height={100} // Reduced height for 2nd place
          imageSrc="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fishita-niwas-gn-ii.webp?alt=media&token=52a5e607-a44a-41ae-8c12-d40bc2c7bcb2" // Placeholder image for 2nd place
        />
        <PodiumPlatform
          position={1}
          height={120} // Reduced height for 1st place
          imageSrc="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fimages%20(6).jfif?alt=media&token=debbe6e2-26a4-41e1-9cc5-ccde771eb6a2" // Placeholder image for 1st place
        />
        <PodiumPlatform
          position={3}
          height={80} // Reduced height for 3rd place
          imageSrc="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fdownload%20(5).jfif?alt=media&token=6dc01496-1c8e-44fc-81a4-ec7688085a9b" // Placeholder image for 3rd place
        />
      </Box>

      {/* <ZoomableElement /> */}
    </>
  );
};

const getBackgroundColor = (percentage) => {
  if (percentage === 100) {
    return "#e8f5e9";
  } // Light green
  if (percentage === 0) {
    return "#ffebee";
  } // Light red
  return "#ffffff"; // White for scores between 0 and 100
};

// zoomable
const ZoomableElement = () => {
  const [zoomed, setZoomed] = useState(true);

  const handleZoom = () => {
    setZoomed(!zoomed);
  };

  return (
    <Box>
      <Box
        sx={{
          //   width: 200,
          //   height: 200,
          //   backgroundColor: "primary.main",
          //   transition: "transform 0.3s ease-in-out",
          //   transform: zoomed ? "scale(1)" : "scale(0.5)",
          //   paddingTop: "70px",

          width: "300%",
          height: zoomed ? "200px" : "100px", // Adjust these values as needed
          transition: "all 0.3s ease-in-out",
          transform: zoomed ? "scale(1)" : "scale(0.8)",
          transformOrigin: "top center",
          marginBottom: zoomed ? "0px" : "0", // Adjust or remove margin when zoomed out
          overflow: "hidden",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      />
      <Button onClick={handleZoom}>{zoomed ? "Zoom Out" : "Zoom In"}</Button>
    </Box>
  );
};

interface Topic {
  id: string;
  name: string;
}

class Quiz extends Component<{}, QuizState> {
  state: QuizState = {
    loading: true,
    error: null,

    quizResults: [],
    leaderboardData: [
      //   { name: "Alice", score: 95 },
      //   { name: "Bob", score: 90 },
      //   { name: "Charlie", score: 85 },
    ],

    //  Note: name: "Who has answered the most questions? | add the 13 questions correct text in the each place holder | add the student name below the image icon | keep 1, 2, 3 rankings"
    topics: [
      { id: "chemistry", name: "Quiz: Chemistry" },
      { id: "physics", name: "Quiz: Physics" },
      { id: "biology", name: "Quiz: Biology" },
    ],
    selectedTopic: "",
    showQuizResults: false, // Initially show the podium

    timerDuration: 300, // Default to 5 minutes (300 seconds)
  };

  componentDidMount() {
    // Simulate data fetching
    setTimeout(() => {
      this.setState({ loading: false, selectedTopic: "chemistry" });
      this.fetchQuizResults("chemistry");
    }, 1000);
  }

  fetchQuizResults = (topicId: string) => {
    // Simulate fetching quiz results based on the selected topic
    const dummyResults: { [key: string]: QuizResult[] } = {
      chemistry: [
        {
          question: "1. What is the chemical formula of water?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "2. What is the atomic number of carbon?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "3. What is the pH value of pure water?",
          studentAnswer: "90/100",
          isCorrect: true,
          attempted: true,
          percentage: 90,
        },
        {
          question: "4. What is the main component of natural gas?",
          studentAnswer: "75/100",
          isCorrect: true,
          attempted: true,
          percentage: 75,
        },
        {
          question: "5. Who is known as the father of modern chemistry?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        {
          question: "6. What is the molecular formula of methane?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        // ... (add more biology questions)
      ],
      physics: [
        {
          question: "1. What is Newton's first law of motion?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "2. What is the speed of light in vacuum?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "3. What is the unit of electrical resistance?",
          studentAnswer: "75/100",
          isCorrect: true,
          attempted: true,
          percentage: 75,
        },
        {
          question:
            "4. What is the value of gravitational acceleration on Earth?",
          studentAnswer: "55/100",
          isCorrect: true,
          attempted: true,
          percentage: 55,
        },
        {
          question: "5. What is the formula for kinetic energy?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        {
          question: "6. Who discovered the law of universal gravitation?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        // ... (add more physics questions)
      ],
      biology: [
        {
          question: "1. What is the function of mitochondria in a cell?",
          studentAnswer: "100/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question: "2. What is the process of photosynthesis?",
          studentAnswer: "10/100",
          isCorrect: true,
          attempted: true,
          percentage: 100,
        },
        {
          question:
            "3. What is the genetic material found in the nucleus of a cell?",
          studentAnswer: "73/100",
          isCorrect: true,
          attempted: true,
          percentage: 73,
        },
        {
          question: "4. What is the primary function of red blood cells?",
          studentAnswer: "60/100",
          isCorrect: true,
          attempted: true,
          percentage: 60,
        },
        {
          question: "5. What is the biological classification system?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        {
          question: "6. What is the role of DNA in inheritance?",
          studentAnswer: "0/100",
          isCorrect: false,
          attempted: true,
          percentage: 0,
        },
        // ... (add more biology questions)
      ],
    };

    this.setState({ quizResults: dummyResults[topicId] || [] });
  };

  handleTopicChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const topicId = event.target.value as string;
    this.setState({ selectedTopic: topicId });
    this.fetchQuizResults(topicId);
  };

  renderPodium = (showQuizResults2) => (
    <Podium showQuizResults={showQuizResults2} />
  );

  toggleQuizResults = () => {
    console.log("Button clicked!"); // Check if this logs
    this.setState((prevState) => ({
      showQuizResults: !prevState.showQuizResults,
    }));
  };

  // // methods for timer
  // handleTimerEnd = () => {
  //   // Handle what happens when the timer ends
  //   console.log("Time's up!");
  //   // You might want to submit the quiz or disable further answers here
  // };

  // handleTimerDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const duration = parseInt(event.target.value, 10) * 60; // Convert minutes to seconds
  //   this.setState({ timerDuration: duration });
  // };

  render() {
    // const { loading, error, quizResults } = this.state;
    const {
      loading,
      error,
      quizResults,
      topics,
      selectedTopic,
      showQuizResults,
      timerDuration,
    } = this.state;

    if (loading) {
      return (
        <Container
          maxWidth="md"
          style={{ textAlign: "center", marginTop: "50px" }}
        >
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading quiz results...
          </Typography>
        </Container>
      );
    }

    if (error) {
      return (
        <Container
          maxWidth="md"
          style={{ textAlign: "center", marginTop: "50px" }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Container>
      );
    }

    //const correctAnswers = quizResults.filter((result) => result.isCorrect);

    return (
      //   <Container maxWidth="md" style={{ marginTop: "30px" }}>
      <Box
        sx={{
          // height: "100vh",
          height: "calc(100vh - 52px)",
          overflowY: "auto", // scroll
          padding: 5,
          margin: 5,
          marginBottom: "48px",
          //   marginTop: "1px",
          scrollbarWidth: "none", // For Firefox
          "&::-webkit-scrollbar": {
            display: "none", // For Chrome, Safari, and Edge
          },
        }}
      >
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          {/* Champions */}
          <img
            // src="../assets/reward.png"
            src="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2FNew%20Logo-%20Classess%20(9%20x%203%20in).png?alt=media&token=7a99bd7d-9e29-42b9-91ee-edd1abbda578"
            height={75}
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              verticalAlign: "middle",
            }}
          />
        </Typography>

        {/* added form label for topic selection suha */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0",
            marginBottom: "0",
            verticalAlign: "middle",
          }}
        >
          <FormControl
            fullWidth
            sx={{
              maxWidth: 250,
              textAlign: "center",
              marginTop: "0",
              marginBottom: "0",
              verticalAlign: "middle",
            }}
          >
            {/* <InputLabel id="topic-select-label">Choose Topic</InputLabel> */}
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedTopic}
              label="Choose Topic"
              // onChange={this.handleTopicChange}
              onChange={
                (this.handleTopicChange = this.handleTopicChange.bind(this))
              }
              sx={{
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  display: "none",
                },
                // Increase font size for the selected item and dropdown menu
                "& .MuiSelect-select": {
                  fontSize: "1.2rem", // Adjust the font size as needed
                },
                "& .MuiMenuItem-root": {
                  fontSize: "1.2rem", // Adjust the font size for menu items
                },
              }}
            >
              {topics.map((topic) => (
                <MenuItem
                  key={topic.id}
                  value={topic.id}
                  style={{
                    marginTop: "0",
                    marginBottom: "0",
                    verticalAlign: "middle",
                  }}
                >
                  {topic.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* added note */}
        <Typography
          style={{
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Who Has Answered the Most Questions?
        </Typography>

        {/* {!showQuizResults && ( */}
        <>
          {this.renderPodium(showQuizResults)}

          {/* Display leaderboard data */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            mb={5}
          >
            {this.state.leaderboardData.map((student, index) => (
              <Box key={student.name} mx={2} textAlign="center">
                <Paper>
                  <EmojiEventsIcon />
                  <Typography variant="subtitle2">{student.name}</Typography>
                  <Typography variant="body2">{student.score}%</Typography>
                </Paper>
              </Box>
            ))}
          </Box>
        </>

        {/* Display leaderboard data */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          mb={4}
        >
          {this.state.leaderboardData.map((student, index) => (
            <Box key={student.name} mx={2} textAlign="center">
              <Paper>
                <EmojiEventsIcon />
                <Typography variant="subtitle2">{student.name}</Typography>
                <Typography variant="body2">{student.score}%</Typography>
              </Paper>
            </Box>
          ))}
        </Box>

        <Button
          //variant="contained"
          variant="text"
          color="primary"
          size="small"
          onClick={this.toggleQuizResults}
          sx={{
            position: "fixed",
            right: 40,
            bottom: 50,
            mt: 0,
            mb: 0,
            padding: "4px 8px", // Custom padding to reduce size
            fontSize: "0.55rem", // Reduce font size
            fontWeight: "bold",
          }}
        >
          {showQuizResults ? "Display Podium" : "Display Quiz"}
        </Button>

        {showQuizResults && (
          <>
            {/* Display leaderboard data */}
            {/* <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              mb={4}
            >
              {this.state.leaderboardData.map((student, index) => (
                <Box key={student.name} mx={2} textAlign="center">
                  <Paper>
                    <EmojiEventsIcon />
                    <Typography variant="subtitle2">{student.name}</Typography>
                    <Typography variant="body2">{student.score}%</Typography>
                  </Paper>
                </Box>
              ))}
            </Box> */}

            {/* here is quiz result table view */}
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    width: "85%",
                    overflow: "hidden",
                    mb: 5,
                    mx: "auto",
                  }}
                >
                 

                  {/* // In your Quiz component's render method: */}
                  {/* <FullScreenTimer
                    initialDuration={timerDuration}
                    onTimerEnd={this.handleTimerEnd}
                  /> */}

                  <Table aria-label="quiz results table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Questions
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}></TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Percentage
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Attempts
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {quizResults.map((result, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor: getBackgroundColor(
                              result.percentage,
                            ),
                          }}
                        >
                          <TableCell>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold", color: "#3f51b5" }}
                            >
                              {result.question}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {result.attempted ? "" : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {result.percentage}%
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box
                              display="flex"
                              alignItems="center"
                              color={result.isCorrect ? "green" : "red"}
                            >
                              {result.isCorrect ? (
                                <CheckCircleIcon sx={{ mr: 1 }} />
                              ) : (
                                <CancelIcon sx={{ mr: 1 }} />
                              )}
                              <Typography
                                variant="body2"
                                sx={{
                                  color: result.isCorrect ? "green" : "red",
                                }}
                              >
                                {result.studentAnswer}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
        {/* </Container> */}
      </Box>
    );
  }
}

export default Quiz;

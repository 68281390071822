import React, { Component } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { withRouter } from "./withRouter";

interface QuizResult {
  question: string;
  studentAnswer: string;
  correctAnswer: string;
  isCorrect: boolean;
}

interface StudentQuizResultProps {
  studentName: string;
  totalScore: number;
  results: QuizResult[];
}

interface StudentQuizResultState {
  loading: boolean;
  error: string | null;
}

const quizResults = [
  {
    question: "1. What is the chemical formula of water?",
    // "studentAnswer": "H2O",
    studentAnswer: "100/100",
    correctAnswer: "H2O",
    isCorrect: true,
  },
  {
    question:
      "2. What type of reaction occurs when an acid reacts with a base?",
    // "studentAnswer": "Neutralization",
    studentAnswer: "100/100",
    correctAnswer: "Neutralization",
    isCorrect: true,
  },
  {
    question: "3. What is the chemical formula of sodium chloride?",
    // "studentAnswer": "NaCl",
    studentAnswer: "100/100",
    correctAnswer: "NaCl",
    isCorrect: true,
  },
  {
    question:
      "4. What type of reaction occurs when an acid reacts with a base?",
    // "studentAnswer": "Neutralization",
    studentAnswer: "100/100",
    correctAnswer: "Neutralization",
    isCorrect: true,
  },
  {
    question: "5. What is the chemical formula of sodium chloride?",
    // "studentAnswer": "NaCl",
    studentAnswer: "100/100",
    correctAnswer: "NaCl",
    isCorrect: true,
  },
  {
    question:
      "6. In the reaction 2H2 + O2 → 2H2O, how many molecules of water are produced?",
    // "studentAnswer": "6",
    studentAnswer: "0/100",
    correctAnswer: "2",
    isCorrect: false,
  },
  {
    question:
      "7. What is the product of the combustion of methane (CH4) in oxygen?",
    // "studentAnswer": "Carbon dioxide",
    studentAnswer: "0/100",
    correctAnswer: "Carbon dioxide and water",
    isCorrect: false,
  },
  {
    question:
      "8. What is the law that states mass is conserved in a chemical reaction?",
    // "studentAnswer": "Law of Conservation of Energy",
    studentAnswer: "0/100",
    correctAnswer: "Law of Conservation of Mass",
    isCorrect: false,
  },
];

class DummyQuiz extends Component<
  StudentQuizResultProps,
  StudentQuizResultState
> {
  state = {
    loading: true,
    error: null,
  };

  componentDidMount() {
    // Simulate data fetching with the mock data
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }

  render() {
    const { loading, error } = this.state;
    const studentName = "Student";
    const totalScore = quizResults.filter((result) => result.isCorrect).length;
    const results = quizResults;

    if (loading) {
      return (
        <Container
          maxWidth="md"
          style={{ textAlign: "center", marginTop: "50px" }}
        >
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Loading quiz results...
          </Typography>
        </Container>
      );
    }

    if (error) {
      return (
        <Container
          maxWidth="md"
          style={{ textAlign: "center", marginTop: "50px" }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Container>
      );
    }

    return (
      <Container maxWidth="md" style={{ marginTop: "30px" }}>
        {/* Card with student name and total score */}
        <Card sx={{ mt: 4, mb: 4, p: 3, backgroundColor: "#f5f5f5" }}>
          <CardContent>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              {studentName}'s Quiz Results
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{ color: "green", fontWeight: "bold", marginTop: "10px" }}
            >
              Total Score: {totalScore} / {results.length}
            </Typography>
          </CardContent>
        </Card>

        {/* Table for displaying quiz results */}
        {results && results.length > 0 ? (
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              overflow: "hidden",
              mb: 4,
              overflowY: "scroll",
              height: "500px",
            }}
          >
            <Table aria-label="quiz results table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Questions
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Attempted
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Correct Answer
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Percentage
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((result, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", color: "#3f51b5" }}
                      >
                        {result.question}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        sx={{ color: result.isCorrect ? "green" : "red" }}
                      >
                        {result.studentAnswer}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {result.correctAnswer}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {result.isCorrect ? (
                        <Box display="flex" alignItems="center" color="green">
                          <CheckCircleIcon sx={{ mr: 1 }} />
                          <Typography
                            style={{ color: "green", alignItems: "center" }}
                          >
                            {/* Correct */}
                          </Typography>
                        </Box>
                      ) : (
                        <Box display="flex" alignItems="center" color="red">
                          <CancelIcon sx={{ mr: 1 }} />
                          <Typography sx={{ color: "red" }}>
                            {/* Incorrect */}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <Typography
            variant="h6"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            No quiz results available.
          </Typography>
        )}
      </Container>
    );
  }
}

export default withRouter(DummyQuiz);
